import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Stock from './Stock';
import Portfolio from './Portfolio';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="Stock">
          <Route path=":ticker" element={<Stock />} />
        </Route>
        <Route path="Portfolio" element={<Portfolio />} />
        <Route path="/fr" element={<App forceLanguage="fr" />} />
        <Route path="fr/Stock">
          <Route path=":ticker" element={<Stock forceLanguage="fr" />} />
        </Route>
        <Route path="fr/Portfolio" element={<Portfolio forceLanguage="fr" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();