import React, { useState,useCallback } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Typography from '@mui/material/Typography';
import TableToBuy from './TableToBuy';
import { useSearchParams } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import WarningIcon from '@mui/icons-material/Warning';
import AddNewStock from './AddNewStock';
import { useTranslation } from 'react-i18next';

function PortfolioConnected() {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [rerender, setRerender] = useState(false);
    const forceUpdate = useCallback(() => {setRerender(!rerender)}, [rerender]);

    return (<>
        <Typography variant="body1" gutterBottom>{t('portfolioIntroduction1')}</Typography>
        <Typography variant="body1" gutterBottom>
            <WarningIcon/> {t('portfolioIntroduction2')} <strong>{t('portfolioIntroduction3')}</strong> {t('portfolioIntroduction4')} <Link to={"/#Methodology"}>{t('methodology')}</Link> {t('portfolioIntroduction5')}</Typography>
        <Typography variant="h4" component="div" gutterBottom>{t('sell')}</Typography>
        <TableToBuy type={'sell'} token={searchParams.get("token")} rerender={rerender} handler={forceUpdate} marketCapDisabled={true} advancedFiltersDisabled={false} />
        <div style={{ height: '200px' }}></div>
        <Typography variant="h4" component="div" gutterBottom>{t('buy')}</Typography>
        <TableToBuy type={'buy'} token={searchParams.get("token")} rerender={rerender} handler={forceUpdate} marketCapDisabled={false} advancedFiltersDisabled={false} />
        <div style={{ height: '200px' }}></div>
        <AddNewStock token={searchParams.get("token")} rerender={rerender} handler={forceUpdate} />
        <div style={{ height: '30px' }}></div>
        <Typography variant="h4" component="div" gutterBottom>{t('keep')}</Typography>
        <TableToBuy type={'keep'} token={searchParams.get("token")} rerender={rerender} handler={forceUpdate} marketCapDisabled={true} advancedFiltersDisabled={false} />
        <div style={{ height: '100px' }}></div>
    </>);
}

export default PortfolioConnected;