import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "title": "Factor investing in the European Economic Area",
      "login": "Login",
      "introduction1": "This tool provides you a solution to implement by yourself a portfolio of stocks from companies based in the European Economic Area. It's built upon the state of the art research on asset pricing and suggests a fully automated selection of stocks based on their characteristics (factors): size, value, profitability, investment, and momentum.",
      "introduction2": "The tool primarly targets French people who can benefit from huge tax gains when investing in the European Economic Area but don't have access to any low-cost factorial fund compliant with their tax wrappers. If you face a similar challenge in your country, we are happy to welcome and help you too!",
      "introduction3": "Here you can build your portfolio, and each week the tool will tell you what stocks you should buy, keep, and sell. There is no human bias in the process, all these hints are purely based on quantitative metrics explained in",
      "methodology": "the methodology",
      "pastPerformance": "Past performance",
      "chartIntroduction": "The chart bellow indicates in euro currency the past performance of my personal portfolio, built upon this tool.",
      "chartDetails": "It is a real life performance: it includes brokerage fees, currency exchange fees, foreign withholding tax on dividends (as a French taxpayer, I don't pay such a tax for French stocks), and financial transaction tax when applicable. It does not include any additional domestic tax on capital gains or dividends.",
      "regressionIntroduction": "We add a monthly regression over the same period of time. In parenthesis are associated p-values.",
      "myPortfolio": "My portfolio",
      "myPortfolioDescription1": "Here is the current state of my personal portfolio, built with",
      "methodology2": "that methodology",
      "myPortfolioDescription2": "When it comes to the profitability factor, I use the Gross Profitability metric ; but you are free to switch to Operating Profitability.",
      "yourPortfolio1": "You can build your own portfolio using our tool by",
      "createAccount": "creating an account",
      "yourPortfolio2": "for free",
      "buy": "Stocks you could buy",
      "buyIntroduction1": "If you don't have a portfolio of stocks yet, you could start by buying the stocks in the table bellow. Depending of when you visit this page, there may not be many. As you will learn in",
      "buyIntroduction2": "we have have stricter rules to include a new stock than to keep an existing one in the portfolio. With the time, by managing your portfolio using this tool, it should reach about 150 names.",
      "methodology3": "Methodology",
      "methodologyIntroduction": "Our methodology is built on research in asset pricing. We believe science can help us identify riskier stocks associated with greater expected returns. We aim to systematically invest in a broadly diversified portfolio of such stocks, in a rules-based manner.",
      "methodologySources": "Our favorite academic papers are:",
      "methodologyUniverse": "Universe",
      "universe1": "We start by looking at all stocks from companies based in the European Economic Area (EEA) because these are those French people have a tax incentive to invest in.",
      "universe2": "We then only keep those with a market capitalisation between 20M€ (micro-cap) and 10B€ (mid-cap).",
      "universe3": "We then exclude financials (hard to properly valuate, REITs are excluded from the French tax wrapper anyway).",
      "methodologyRanking": "Ranking",
      "ranking1": "Each week, for these stocks, we compute :",
      "ranking2": "Four value ranks, based on Price-to-book, Price-to-earnings, Price-to-sales, and Price-earnings-to-Growth.",
      "ranking3": "Two profitability ranks, based on Gross Profitability and Operating Profitability.",
      "ranking4": "An investment rank, based on Asset growth.",
      "ranking5": "A momentum rank, based on recent change in the price of the stock.",
      "ranking6": "You can select a stock to see the details of these calculations. There is a Q&A help at the bottom of the page of each stock.",
      "methodologyBuy": "Rules to buy",
      "buy1": "We then put a “stock you could buy” label on stocks which meet all these criteria:",
      "buy2": "The momentum rank is better than the median (score is greater than 50), and",
      "buy3": "the value rank is cheaper than the 3rd decile (score is greater than 70), and",
      "buy4": "the profitability rank of your choice is better than the 7th decile (score is greater than 70), and",
      "buy5": "the investment rank is better than the 8th decile (score is greater than 20).",
      "methodologySell": "Rules to sell",
      "sell1": "We put a “stock you could sell” label on stocks which meet these criteria:",
      "sell2": "The momentum rank is worse than the median (score is less than 50)",
      "sell3": "and at least one of the following:",
      "sell4": "the value rank is more expensive than the 4rd decile (score is less than 60), or",
      "sell5": "the profitability rank of your choice is worse than the 6th decile (score is less than 60), or",
      "sell6": "the investment rank is worse than the 8th decile (score is less than 20).",
      "methodologyWeighting": "Weighting scheme",
      "weightingDetails": "The tool tells nothing about weights. We use a simple equal weighting scheme. We don't rebalance, we just use the money from sold stocks and dividends to buy the relevant stocks.",
      "methodologyResources": "External resources",
      "resources1": "To better understand this methodology, you could start by reading",
      "and": "and",
      "resources2": "Both are easy to read.",
      "faq": "Frequently asked questions",
      "avws": "Shouldn't we use Avantis Global Small Cap Value ETF?",
      "avwsDetails": "French people can't access this ETF in a tax efficient manner. If you don't face such a challenge, this fund can be an excellent choice for a small-value exposure at the Developed World markets.",
      "zprx": "Shouldn't we use SPDR MSCI Europe Small Cap Value Weighted ETF?",
      "zprxDetails": "French people can't access this ETF in a tax efficient manner. If you don't face such a challenge, this fund can be a good enough choice for a small-value exposure at the European market.",
      "ie": "Shouldn't we use Indépendance et Expansion Europe Small?",
      "ieDetails1": "We would be reluctant to invest in a fund with such high fees.",
      "ieDetails2": "To a lesser extent, its low diversification with about 50 names also is a concern: in the small cap world, we consider it to be a bit too concentrated. See",
      "ieDetails3": "We also think that",
      "methodology4": "our methodology",
      "ieDetails4": "is better supported by research.",
      "mms": "Shouldn't we use Lyxor MSCI EMU Small Cap?",
      "mmsDetails": "This ETF is PEA compliant but does not have a value tilt. The size factor is known not to work as a standalone and is only useful in combination with others. From that perspective, we don't recommend this fund. See",
      "fees": "What are your fees? Is the past performance net return?",
      "feesDetails1": "The first layer of fees include brokerage fees, currency exchange fees, and Financial Transaction Tax when buying the largest French and Spanish stocks. The first four years of the portfolio, this layer sums to 0.37% a year. Since 2024, these fees are reduced thanks to a new fee structure at Saxo Bank.",
      "feesDetails2": "The second layer of fees is withholding tax on dividends. As a French taxpayer, I do not pay this tax on French dividends. Over the calendar years 2021 to 2023, this layer summed to 0.63% a year, and I was able to recover a small part of it under tax treaties.",
      "feesDetails3": "Tax and fees vary a lot across countries, so you should carefully do your own researchs.",
      "feesDetails4": "The performance displayed on this page takes those fees into account, it is comparable to the funds used as benchmarks.",
      "rebalance": "How do you rebalance?",
      "rebalanceDetails": "I don't. I only use the money from sold stocks and dividends to invest in whatever the algorithm tells me to.",
      "accumulatingWrapper": "I will have to pay taxes each time I sell. Or I have high trading costs. Is it worth it?",
      "accumulatingWrapperDetails": "Tax vary a lot from country to country. In France we have an accumulating tax wrapper with low trading fees. You should carefully consider how a portfolio of individual stocks would be taxed compared to a fund in your country, and how expensive its maintenance would be, before using our app.",
      "profitabilityMetric": "Should I prefer Gross Profitability or Operating Profitability?",
      "profitabilityMetricDetails1": "Gross Profitability was first introduced by Robert Novy-Marx in 2013 in",
      "profitabilityMetricDetails2": "The profitability factor has then been added to the Fama-French model in 2014 in",
      "profitabilityMetricDetails3": "they used Operating Profitability, a slightly different definition which notably netted Selling, General and Administrative expenses (SG&A) from Gross Profit",
      "profitabilityMetricDetails4": "Operating Profitability probably is a better metric of the profitability factor. In our experiments however, we could not compute it for about 20% of companies. That's often because we don't get SG&A from our data provider. We prefer to stick to Gross Profitability and avoid a 20% drop in diversification.",
      "outsideEEA": "Do you diversify outside of the European Economic Area ?",
      "outsideEEADetails": "Yes. I have a home region bias explained by a tax incentive. But I also invest in the US through Avantis U.S. Small Cap Value ETF. In the UCITS space, you could use Avantis Global Small Cap Value ETF.",
      "avdv": "Shouldn't I just mimic the EEA part of AVDV or DISV or AVWS in my PEA?",
      "avdvDetails1": "That would be a very sensible strategy to get the best of both worlds: a good methodology and a tax benefit, although you might be prepared to rebalance sometimes to follow their more complex weighting scheme.",
      "avdvDetails2": "More importantly, you will not get the same portfolio at the end. The one we focus on here has a lot of micro-caps, which are liquid enough for individual investors but not for an institutional one.",
      "howmanystocks": "How many stocks is enough?",
      "howmanystocksDetails1": "let us think that the bare minimum is 50 stocks. However skewness in stocks returns in known to be more a problem in the small cap world so we would be reluncant to invest in a portfolio of less than 100 names.",
      "howmanystocksDetails2": "The last time we checked, 90% of the weight of Avantis International Small Cap Value ETF and Dimensional International Small Cap Value ETF in the European Economic Area (EEA) was concentrated among about 125 names.",
      "howmanystocksDetails3": "This app targets a portfolio of 150 names out of nearly 2.000 in the EEA small-cap universe.",
      "sectorcap": "Do you cap sector or country weights?",
      "sectorcapDetails": "We exclude financials (Financial Services, Financial, and Real Estate), but we don't do further adjustements.",
      "broker": "Which brokerage do you use to execute the trades?",
      "brokerDetails1": "I don’t have much choice since I have to select those who can handle the French tax wrappers (PEA & PEA-PME).",
      "brokerDetails2": "For the main account (PEA), there only exist two brokerages which offer access to all the major countries allowed by the wrapper: Saxo Bank, and maybe Crédit Agricole. I use Saxo Bank.",
      "brokerDetails3": "For the secondary account (PEA-PME), there is a lot more choice since I only use it for stocks listed on Euronext. I use Bourse Direct.",
      "added": "added!",
      "select": "Please select a stock!",
      "buyanother": "Buy another stock",
      "addportfolio": "Add to portfolio",
      "myportfolio": "My portfolio",
      "yourportfolio": "Your portfolio",
      "back": "Back to main page",
      "portfolioIntroduction1": "On this page, you are able to both update your portfolio (i.e. tell us what stocks you own) and visualize what our algorithm tells about each of the stocks (whether you should keep it, sell it, or buy a stock you don't already own).",
      "portfolioIntroduction2": "There is no human bias involved. There is no human verification either.",
      "portfolioIntroduction3": "It is your responsability to make sure the information you find here is relevant.",
      "portfolioIntroduction4": "You should understand",
      "portfolioIntroduction5": "and verify there is not a special event around the specific stocks recommended (examples include a takeover or a lack of liquidity). We rely on a third party data provider, and are not responsible if the financial data we take as an input are wrong, resulting in suggestions not in line with our methodology.",
      "sell": "Stocks you could sell",
      "keep": "Stocks you could keep",
      "usage": "What will I be able to do with the app?",
      "usageDetails": "You will be able to store a list of stocks (your portfolio) and the app will tell you what our algorithm thinks about each of them (whether you should keep it, sell it, or buy a stock you don't already own).",
      "googleAccount": "What do I need?",
      "googleAccountDetails1": "You need a Google account to use the app. That's because it's convenient for us not to have to store your credentials, send you confirmation emails and so on.",
      "googleAccountDetails2": "In exchange for the small effort creating such an account might be if you don't already have one, we promise we will not spy on you nor collect any data. The only thing we'll remember about you is your email address and the portfolio you'll build on the app.",
      "free": "Is it free?",
      "freeDetails": "Yes.",
      "signin": "Sign in with Google",
      "name":"Name",
      "titleStock": "What we know about",
      "previous": "Back to previous page",
      "basicInfo": "Basic information",
      "asof": "as of",
      "compliant": "compliant",
      "notcompliant": "not compliant",
      "peapme": "with PEA-PME",
      "marketcap": "Market cap",
      "volume": "Daily volume",
      "sector": "Sector",
      "let": "Let",
      "betheprice": "be the price of the stock",
      "weeksago": "weeks ago, adjusted for dividends and splits",
      "questions": "Questions & Answers",
      "peapmeDefinition": "What is PEA-PME?",
      "peapmeDefinitionDetails": "It is a French tax wrapper. Please ignore this if you are not a French taxpayer.",
      "pbComputation": "How is Price-to-book/Price-to-earnings/Price-to-sales/Price-earnings-to-Growth computed?",
      "pbComputationDetails": "We get these values from our data provider, there is no calculation on our side.",
      "valueScoreComputation": "How is the Value score set?",
      "valueScoreComputationDetails1": "We consider companies for which the Price-to-book (for example) is known. We exclude those in the financial sector (Financial Services, Financial, and Real Estate). We exclude those with a tiny liquidity, i.e. volume is less than 1k€/day. We exclude micro-cap stocks, i.e. market cap is less than 20M€.",
      "valueScoreComputationDetails2": "We then sort those companies by Price-to-book, they are ranked from 1 (Price-to-book is high) to 100 (Price-to-book is low).",
      "grossProfitabilityComputation": "How is Gross Profitability computed?",
      "grossProfitabilityComputationDetails": "We make sure Gross Profit is known and has less than one year and a quarter. We look for the most recent value for Total Assets and make sure it has less than one year and two quarters. We then divide one by the other.",
      "grossProfitabilityScoreComputation": "How is the Gross Profitability score set?",
      "grossProfitabilityScoreComputationDetails1": "We consider companies for which the Gross Profitability could be computed. We exclude those in the financial sector (Financial Services, Financial, and Real Estate). We exclude those with a tiny liquidity, i.e. volume is less than 1k€/day. We exclude micro-cap stocks, i.e. market cap is less than 20M€.",
      "grossProfitabilityScoreComputationDetails2": "We then sort those companies by Gross Profitability, they are ranked from 1 (Gross Profitability is low) to 100 (Gross Profitability is high).",
      "operatingProfitabilityComputation": "How is Operating Profitability computed?",
      "operatingProfitabilityComputationDetails1":"We make sure Gross Profit is known and has less than one year and a quarter. We do the same for Selling General And Administration. We then make sure that Stockholders Equity is known and has less than one year and two quarters. We then do the calculation using the formula indicated on this page, assuming Interest Expense and Minority Interest are zero if our data provider does not tell us otherwise. Only Interest Expense of less than one year and a quarter and Minority Interest of less than one year and two quarters are considered.",
      "operatingProfitabilityScoreComputation": "How is the Operating Profitability score set?",
      "operatingProfitabilityScoreComputationDetails1": "We consider companies for which the Operating Profitability could be computed. We exclude those in the financial sector (Financial Services, Financial, and Real Estate). We exclude those with a tiny liquidity, i.e. volume is less than 1k€/day. We exclude micro-cap stocks, i.e. market cap is less than 20M€.",
      "operatingProfitabilityScoreComputationDetails2": "We then sort those companies by Operating Profitability, they are ranked from 1 (Operating Profitability is low) to 100 (Operating Profitability is high).",
      "assetGrowthComputation": "How is Asset Growth computed?",
      "assetGrowthComputationDetails": "We only consider annual reports for this ratio. We search for the most recent value of Total Asset, we make sure it has less than one year and two quarters. We then search for the value the year before. We divide the first by the other.",
      "investmentScoreComputation": "How is Investment score set?",
      "investmentScoreComputationDetails1": "We consider companies for which the Asset growth could be computed. We exclude those in the financial sector (Financial Services, Financial, and Real Estate). We exclude those with a tiny liquidity, i.e. volume is less than 1k€/day. We exclude micro-cap stocks, i.e. market cap is less than 20M€.",
      "investmentScoreComputationDetails2":"We then sort those companies by Asset growth, they are ranked from 1 (Asset growth is high) to 100 (Asset growth is low).",
      "momentumScoreComputation": "How is Momentum score set?",
      "momentumScoreComputationDetails1":"We consider companies for which the Momentum could be computed (using the formula on the page). We exclude those in the financial sector (Financial Services, Financial, and Real Estate). We exclude those with a tiny liquidity, i.e. volume is less than 1k€/day. We exclude micro-cap stocks, i.e. market cap is less than 20M€.",
      "momentumScoreComputationDetails2":"We then sort those companies by Momentum, they are ranked from 1 (Momentum is low) to 100 (Momentum is high).",
      "switchProfitability": "Use gross profitability instead of operating profitability",
      "country": "Country",
      "company": "Company",
      "seo_about_us_page_title": "Implement by yourself a portfolio of stocks from companies based in the European Economic Area",
      "profitability-metric": "Profitability metric",
      "value-metric": "Value metric",
      "market-cap-range": "Market cap range",
      "apply-filters": "Apply these filters:",
      "max-500": "Please note that at most 500 items will be displayed, due to technical reasons.",
      "value-threshold": "Value Threshold",
      "profitability-threshold": "Profitability Threshold",
      "investment-threshold": "Investment Threshold",
      "momentum-threshold": "Momentum Threshold",
    }
  },
  fr: {
    translation: {
      "title": "Piloter son PEA grâce à la science",
      "login": "Se connecter",
      "introduction1": "Cet outil vous fournit une solution pour piloter par vous-même un portefeuille d'actions éligibles au PEA. Il est construit sur l'état de l'art en recherche économique et suggère une sélection d'actions de manière totalement automatisée, en se basant sur leurs caractéristiques (facteurs) : size, value, profitability, investment et momentum.",
      "introduction2": "L'outil cible principalement les contribuables français qui peuvent bénéficier d'avantages fiscaux substantiels en investissant à travers un PEA, mais n'ont accès à aucun fonds factoriel à bas coûts qui y est éligible. Si vous n'êtes pas français mais rencontrez une problématique similaire, nous sommes heureux de pouvoir vous aider également !",
      "introduction3": "Vous pouvez construire votre portefeuille, et chaque semaine l'outil vous dira quelles actions vous devriez acheter, conserver ou vendre. Il n'y a pas de biais humains dans ce processus, toutes ces recommandations sont purement basées sur des indicateurs quantitatifs expliqués dans",
      "methodology": "la méthodologie",
      "pastPerformance": "Performance passée",
      "chartIntroduction": "Le graphique ci-dessous indique en euros la performance passée de mon portefeuille construit avec cet outil.",
      "chartDetails": "Il s'agit d'une performance réelle : elle inclut les frais de courtage et de change, les retenues à la source sur les dividendes étrangers et les taxes sur les transactions financières. Elle n'inclut pas les prélèvements sociaux, dans le cadre du PEA ceux-ci sont différés.",
      "regressionIntroduction": "Nous ajoutons une régression des performances mensuelles sur la même période. Entre parenthèses sont indiquées les p-values.",
      "myPortfolio": "Mon portefeuille",
      "myPortfolioDescription1": "Voici la composition actuelle de mon portefeuille, construit avec",
      "methodology2": "cette méthodologie",
      "myPortfolioDescription2": "Pour ce qui est du facteur profitability, j'utilise la métrique Gross Profitability ; mais vous pouvez utiliser Operating Profitability si vous préférez.",
      "yourPortfolio1": "Vous pouvez construire votre propre portefeuille basé sur notre outil en",
      "createAccount": "créant un compte",
      "yourPortfolio2": "gratuitement",
      "buy": "Des actions que vous pourriez acheter",
      "buyIntroduction1": "Si vous n'avez pas déjà un portefeuille d'actions, vous pourriez commencer par acheter celles présentées dans le tableau ci-dessous. En fonction du moment où vous visitez cette page, elles pourraient ne pas être très nombreuses. Comme vous l'apprendrez dans",
      "buyIntroduction2": "nous avons des règles plus strictes pour ajouter une nouvelle action dans le portefeuille que pour en conserver une déjà présente. Avec le temps, en gérant votre portefeuille avec cet outil, il devrait atteindre environ 150 lignes.",
      "methodology3": "Méthodologie",
      "methodologyIntroduction": "Notre méthodologie est construite sur la recherche en valorisation d'actifs. Nous pensons que la science peut nous aider à identifier des actions plus risquées que les autres, associées à de plus grands retours attendus. Nous avons pour objectif d'investir de manière systématique dans un portefeuille diversifié de telles actions, en suivant des règles strictes.",
      "methodologySources": "Nos articles de recherche de prédilection sont :",
      "methodologyUniverse": "Univers",
      "universe1": "Nous commençons par sélectionner les actions éligibles au PEA, c'est-à-dire celles qui ont leur siège dans l'Espace Economique Européen.",
      "universe2": "Ensuite, nous ne conservons que celles qui ont une une capitalisation comprise entre 20 millions d'euros (micro-cap) et 10 milliards d'euros (mid-cap).",
      "universe3": "Nous excluons enfin les entreprises financières (elles sont difficiles à valoriser correctement, les SIIC sont de toutes manières exclues du PEA).",
      "methodologyRanking": "Classements",
      "ranking1": "Chaque semaine, pour ces actions, nous calculons :",
      "ranking2": "Quatre rangs value, basé sur le Price-to-book, Price-to-earnings, Price-to-sales, et Price-earnings-to-Growth.",
      "ranking3": "Deux rangs profitability, basés sur Gross Profitability et Operating Profitability.",
      "ranking4": "Un rang investment, basé sur l'Asset growth.",
      "ranking5": "Un rang momentum, basé sur les changements récents de prix de l'action.",
      "ranking6": "Vous pouvez sélectionner une action pour voir le détail de ces calculs. Il y a une section questions-réponses en bas de la page de chaque action.",
      "methodologyBuy": "Règles pour acheter",
      "buy1": "Nous mettons ensuite un libellé « action que vous pourriez acheter » sur les actions qui réunissent ces critères :",
      "buy2": "Le rang momentum est meilleur que la médiane (le score est plus grand que 50), et",
      "buy3": "le rang value est moins cher que le troisième décile (le score est plus grand que 70), et",
      "buy4": "le rang profitability de votre choix est meilleur que le septième décile (le score est plus grand que 70), et",
      "buy5": "le rang investment est meilleur que le huitième décile (le score est plus grand que 20).",
      "methodologySell": "Règles pour vendre",
      "sell1": "Nous mettons un libellé « action que vous pourriez vendre » sur les actions qui réunissent ces critères :",
      "sell2": "Le rang momentum est moins bon que la médiane (le score est de moins de 50)",
      "sell3": "et au moins l'un des critères suivants :",
      "sell4": "le rang value est plus coûteux que le quatrième décile (le score est de moins de 60), ou",
      "sell5": "le rang profitability de votre choix est moins bon que le sixième décile (le score est de moins de 60), ou",
      "sell6": "le rang investment est moins bon que le huitième décile (le score est de moins de 20).",
      "methodologyWeighting": "Schéma de pondérations",
      "weightingDetails": "L'outil ne dit rien des poids. Nous utilisons un simple schéma de pondérations égales entre nos lignes. Nous ne rebalançons pas le portefeuille, nous utilisons simplement l'argent issu des ventes et des dividendes pour réinvestir dans les actions pertinentes.",
      "methodologyResources": "Ressources externes",
      "resources1": "Pour mieux comprendre la méthodologie, vous pourriez commencer par lire",
      "and": "et",
      "resources2": "Les deux sont faciles à lire, mais disponibles uniquement en anglais.",
      "faq": "Foire aux questions",
      "avws": "Ne devrions nous pas utiliser Avantis Global Small Cap Value ETF ?",
      "avwsDetails": "Cet ETF n'est pas éligible au PEA. Si vous n'êtes pas contribuable français, c'est un bon produit à considérer pour un exposition aux actions small-value des marchés des pays développés.",
      "zprx": "Ne devrions nous pas utiliser SPDR MSCI Europe Small Cap Value Weighted ETF ?",
      "zprxDetails": "Cet ETF n'est pas éligible au PEA. Si vous n'êtes pas contribuable français, c'est un bon produit à considérer pour un exposition aux actions small-value du marché européen.",
      "ie": "Ne devrions nous pas utiliser Indépendance et Expansion Europe Small ?",
      "ieDetails1": "Nous serions réticents à investir dans un fonds avec des frais si importants.",
      "ieDetails2": "Dans une moindre mesure, sa faible diversification avec environ 50 lignes nous apparaît aussi comme un problème, en particulier dans le monde des petites capitalisations. Voyez",
      "ieDetails3": "Nous pensons aussi que",
      "methodology4": "notre méthodologie",
      "ieDetails4": "est fondée sur une recherche de meilleure qualité.",
      "mms": "Ne devrions-nous pas utiliser Lyxor MSCI EMU Small Cap ?",
      "mmsDetails": "Cet ETF est éligible au PEA mais n'a pas d'exposition au facteur value. Le facteur size est connu pour ne pas fonctionner seul, il est seulement utile de manière combinée avec d'autres. De ce point de vue, nous ne recommandons pas ce fonds. Voyez à ce propos ",
      "fees": "Quels sont vos frais ? Les performances passées affichées sont-elles nettes de frais ?",
      "feesDetails1": "Le premier étage de frais se compose des frais de courtage, de change, et de la taxe sur les transactions financières prélevées à l'achat des plus grosses capitalisations françaises et espagnoles. Les quatre premières années du portefeuille, cet étage s'élevevait à 0,37% par an. Depuis 2024, ces frais sont réduits par application de la nouvelle grille tarifaire de Saxo Banque.",
      "feesDetails2": "Le deuxième étage de frais est constitué des retenues à la source sur les dividendes étrangers. Sur les années calendaires 2021 à 2023, cet étage s'élèvevait à 0,63%, dont une petite partie est récupérable en s'adressant aux administrations fiscales des pays concernés.",
      "feesDetails3": "Les frais et taxes varient considérablement selon les pays, si vous n'êtes pas français vous devriez soigneusement faire vos propres recherches.",
      "feesDetails4": "La performance affichée sur cette page prend ces frais en compte, elle est comparable à celle des fonds utilisés comme benchmarks.",
      "rebalance": "Comment rebalancez-vous ?",
      "rebalanceDetails": "Je ne le fais pas. Je me contente d'utiliser l'argent des ventes et des dividendes pour réinvestir dans les actions indiquées par l'outil.",
      "accumulatingWrapper": "Je n'ai pas accès au PEA. Je devrai payer des taxes à chaque vente. Ou j'ai des frais élevés. L'outil vaut-il le coup ?",
      "accumulatingWrapperDetails": "Les taxes varient beaucoup selon les pays. En France le PEA est une enveloppe fiscale accumulative associée à de faibles frais de courtages. Vous devriez examiner soigneusement comment un portefeuille de titres vifs serait taxé comparé à un fonds dans votre pays, et à quel point sa maintenance serait coûteuse, avant d'utiliser notre application.",
      "profitabilityMetric": "Devrais-je préférer Gross Profitability ou Operating Profitability ?",
      "profitabilityMetricDetails1": "La métrique Gross Profitability a été présentée pour la première fois par Robert Novy-Marx en 2013 dans",
      "profitabilityMetricDetails2": "Le facteur profitability a été ajouté au modèle Fama-French en 2014 dans",
      "profitabilityMetricDetails3": "ils ont utilisé la métrique Operating Profitability, une définition légèrement différente qui raffine notamment la métrique Gross Profit en enlevant la part du chiffre d'affaire consacrée aux dépenses Selling, General and Administrative",
      "profitabilityMetricDetails4": "Operating Profitability est probablement une meilleur métrique pour cibler le facteur profitability. Dans nos expériences cependant, nous ne pouvions pas la calculer pour environ 20% des entreprises. Souvent, la valeur de SG&A manque chez notre fournisseur de données. Nous préférons donc nous en tenir à la Gross Profitability et éviter une chute de 20% en terme de diversification.",
      "outsideEEA": "Diversifiez-vous en dehors de l'Espace Economique Européen ?",
      "outsideEEADetails": "Oui. J'ai un biais pour mon continent d'origine expliqué par l'incitation fiscale. Mais j'investis aussi aux Etats-Unis à travers le fonds Avantis U.S. Small Cap Value ETF. Parmi les fonds compatibles avec la réglementation MIFID II, vous pourriez utiliser Avantis Global Small Cap Value ETF.",
      "avdv": "Ne devrais-je pas simplement extraire la partie EEE du fonds AVDV ou DISV ou AVWS et en reporter la composition dans mon PEA ?",
      "avdvDetails1": "Ce serait une très bonne stratégie pour obtenir le meilleur de deux mondes : une bonne méthodologie et un avantage fiscal, bien que vous devriez vous préparer à rebalancer régulièrement pour suivre leur schéma de pondérations plus complexe.",
      "avdvDetails2": "Plus important : vous n'aurez pas le même portefeuille à la fin. Celui sur lequel nous nous concentrons ici comporte beaucoup de micro-capitalisations, qui sont assez liquides pour des investisseurs individuels mais pas pour des institutionnels.",
      "howmanystocks": "Combien faut-il d'actions ?",
      "howmanystocksDetails1": "nous laisse penser que le strict minimum est 50 lignes. Cependant, l'asymétrie dans la performance des actions est connue pour être davantage un problème pour les petites capitalisations, donc nous serions réticents à investir dans un portefeuille de moins de 100 lignes.",
      "howmanystocksDetails2": "La dernière fois que nous avons vérifié, 90% du poids des fonds Avantis International Small Cap Value ETF et Dimensional International Small Cap Value ETF dans l'Espace Economique Européen était concentré dans environ 125 lignes.",
      "howmanystocksDetails3": "Cette application cible un portefeuille de 150 lignes sur environ 2 000 actions éligibles au PEA analysées par l'outil.",
      "sectorcap": "Imposez-vous un maximum d'exposition à un pays ou un secteur ?",
      "sectorcapDetails": "Nous excluons les entreprises financières (Financial Services, Financial, et Real Estate), mais nous ne faisons pas d'autre ajustement.",
      "broker": "Quel courtier utilisez-vous pour placer les ordres ?",
      "brokerDetails1": "Je n'ai pas beaucoup de choix dans la mesure où je ne peux utiliser que des courtiers qui gèrent les PEA & PEA-PME.",
      "brokerDetails2": "Pour le PEA, seuls Saxo Banque et peut-être Crédit Agricole offrent un accès à tous les grands pays éligibles au PEA. J'utilise Saxo Banque.",
      "brokerDetails3": "Pour le PEA-PME, comme je ne l'utilise que pour traiter des actions cotées sur Euronext il y a davantage de choix. J'utilise Bourse Direct.",
      "added": "ajouté !",
      "select": "Merci de choisir une action !",
      "buyanother": "Acheter une autre action",
      "addportfolio": "Ajouter au portefeuille",
      "myportfolio": "Mon portefeuille",
      "yourportfolio": "Votre portefeuille",
      "back": "Retour à la page principale",
      "portfolioIntroduction1": "Sur cette page, vous pouvez à la fois mettre à jour votre portefeuille (i.e. nous dire quelles actions vous possédez) et visualiser ce que notre algorithme pense de chacune de ces actions (si vous devriez les conserver, les vendre, ou acheter une action que vous ne possédez pas déjà).",
      "portfolioIntroduction2": "Il n'y a aucun biais humain impliqué, ni aucune vérification manuelle.",
      "portfolioIntroduction3": "Il est de votre responsabilité de vérifier que l'information que vous trouvez ici est pertinente.",
      "portfolioIntroduction4": "Vous devriez comprendre",
      "portfolioIntroduction5": "et vérifier qu'il n'y a pas un événement spécial autour des actions recommandées (par exemple une OPA ou un manque de liquidité). Nous dépendons d'un fournisseur de données tiers, et ne pouvons être tenu pour responsables si des données financières incorrectes en entrée nous menaient à suggérer des actions incohérentes avec notre méthodologie.",
      "sell": "Des actions que vous pourriez vendre",
      "keep": "Des actions que vous pourriez conserver",
      "usage": "Que serai-je en mesure de faire avec l'application ?",
      "usageDetails": "Vous serez en mesure d'enregistrer une liste d'actions (votre portefeuille) et l'application vous dira ce que notre algorithme pense de chacune d'entre elles (si vous devriez les conserver, les vendre, ou acheter une action que vous ne possédez pas déjà).",
      "googleAccount": "De quoi ai-je besoin ?",
      "googleAccountDetails1": "Vous avez besoin d'un compte Google pour utiliser l'application. Cela nous dispense d'avoir à stocker vos identifiants, vous envoyer des mails de confirmations etc.",
      "googleAccountDetails2": "En échange du petit effort d'en créer un si vous n'en possédez pas déjà, nous nous engageons à ne collecter aucune donnée sur vous. La seule chose que nous enregistrerons est votre adresse mail et le portefeuille que vous construirez sur l'application.",
      "free": "Est-ce gratuit ?",
      "freeDetails": "Oui.",
      "signin": "Se connecter avec Google",
      "name": "Nom",
      "titleStock": "Ce que nous savons de",
      "previous": "Retour à la page précédente",
      "basicInfo": "Informations de base",
      "asof": "en date du",
      "compliant": "éligible",
      "notcompliant": "non éligible",
      "peapme": "au PEA-PME",
      "marketcap": "Capitalisation",
      "volume": "Volume journalier",
      "sector": "Secteur",
      "let": "Soit",
      "betheprice": "le prix de l'action il y a",
      "weeksago": "semaines, ajusté des dividendes et fractionnements",
      "questions": "Questions & Réponses",
      "peapmeDefinition": "Qu'est-ce que le PEA-PME ?",
      "peapmeDefinitionDetails": "Il s'agit d'une enveloppe fiscale française dédiée à l'investissement en actions de petites et moyennes entreprises.",
      "pbComputation": "Comment le Price-to-book/Price-to-earnings/Price-to-sales/Price-earnings-to-Growth est-il calculé ?",
      "pbComputationDetails": "Nous obtenons ces valeurs auprès de notre fournisseur de données, il n'y a pas de calcul de notre côté.",
      "valueScoreComputation": "Comment est attribué le score value ?",
      "valueScoreComputationDetails1": "On observe les entreprises pour lesquelles le Price-to-book (par exemple) est connu. On exclue celles du secteur financier (Financial Services, Financial, et Real Estate). On exclue celles avec une très faible liquidité, c'est-à-dire que le volume est de moins de 1k€/jour. On exclue les micro-capitalisations, c'est-à-dire les entreprises avec une capitalisation inférieure à 20 millions d'euros.",
      "valueScoreComputationDetails2": "Nous trions ensuite ces entreprises par Price-to-book, de 1 (Price-to-book est élevé) à 100 (Price-to-book est bas).",
      "grossProfitabilityComputation": "Comment est calculé la Gross Profitability ?",
      "grossProfitabilityComputationDetails": "On s'assure que le Gross Profit est connu et date de moins d'un an et un trimestre. On recherche la valeur la plus récente de Total Assets et on s'assure que celle-ci date de moins d'un an et deux trimestres. On divise ensuite le premier par le second.",
      "grossProfitabilityScoreComputation": "Comment est attribué le score Gross Profitability ?",
      "grossProfitabilityScoreComputationDetails1": "On observe les entreprises pour lesquelles la Gross Profitability a pu être calculée. On exclue celles du secteur financier (Financial Services, Financial, et Real Estate). On exclue celles avec une très faible liquidité, c'est-à-dire que le volume est de moins de 1k€/jour. On exclue les micro-capitalisations, c'est-à-dire les entreprises avec une capitalisation inférieure à 20 millions d'euros.",
      "grossProfitabilityScoreComputationDetails2": "Nous trions ensuite ces entreprises par Gross Profitability, de 1 (Gross Profitability est faible) à 100 (Gross Profitability est élevée).",
      "operatingProfitabilityComputation": "Comment est calculé l'Operating Profitability ?",
      "operatingProfitabilityComputationDetails1":"On s'assure que le Gross Profit est connu et date de moins d'un an et un trimestre. On fait de même pour Selling General And Administration. On s'assure ensuite que Stockholders Equity est connu et date de moins d'un an et deux trimestres. On effectue ensuite le calcul en utilisant la formule indiquée sur la page, en supposant que Interest Expense et Minority Interest sont nuls si notre fournisseur de données ne nous dit pas le contraire. Seuls les Interest Expense de moins d'un an et un trimestre et les Minority Interest de moins d'un an et deux trimestres sont pris en compte.",
      "operatingProfitabilityScoreComputation": "Comment est attribué le score Operating Profitability ?",
      "operatingProfitabilityScoreComputationDetails1": "On observe les entreprises pour lesquelles l' Operating Profitability a pu être calculée. On exclue celles du secteur financier (Financial Services, Financial, et Real Estate). On exclue celles avec une très faible liquidité, c'est-à-dire que le volume est de moins de 1k€/jour. On exclue les micro-capitalisations, c'est-à-dire les entreprises avec une capitalisation inférieure à 20 millions d'euros.",
      "operatingProfitabilityScoreComputationDetails2": "Nous trions ensuite ces entreprises par Operating Profitability, de 1 (Operating Profitability est faible) à 100 (Operating Profitability élevée).",
      "assetGrowthComputation": "Comment l'Asset Growth est-il calculé ?",
      "assetGrowthComputationDetails": "Nous observons seulement les rapports annuels pour ce ratio. Nous recherchons la valeur la plus récente de Total Asset, nous nous assurons qu'elle date de moins d'un an et deux trimestres. Nous recherchons ensuite la valeur de l'année précédente. Nous divisons enfin la première par la deuxième.",
      "investmentScoreComputation": "Comment le score Investment est-il attribué ?",
      "investmentScoreComputationDetails1": "On observe les entreprises pour lesquelles l'Asset growth a pu être calculée. On exclue celles du secteur financier (Financial Services, Financial, et Real Estate). On exclue celles avec une très faible liquidité, c'est-à-dire que le volume est de moins de 1k€/jour. On exclue les micro-capitalisations, c'est-à-dire les entreprises avec une capitalisation inférieure à 20 millions d'euros.",
      "investmentScoreComputationDetails2":"Nous trions ensuite ces entreprises par Asset growth, de 1 (Asset growth est élevé) à 100 (Asset growth est faible).",
      "momentumScoreComputation": "Comment le score momentum est-il attribué ?",
      "momentumScoreComputationDetails1":"On observe les entreprises pour lesquelles le Momentum a pu être calculé (en utilisant la formule sur la page). On exclue celles du secteur financier (Financial Services, Financial, et Real Estate). On exclue celles avec une très faible liquidité, c'est-à-dire que le volume est de moins de 1k€/jour. On exclue les micro-capitalisations, c'est-à-dire les entreprises avec une capitalisation inférieure à 20 millions d'euros.",
      "momentumScoreComputationDetails2":"Nous trions ensuite ces entreprises par Momentum, de 1 (le momentum est faible) à 100 (le momentum est élevé).",
      "switchProfitability": "Utiliser la gross profitability à la place de l'operating profitability",
      "country": "Pays",
      "company": "Entreprise",
      "seo_about_us_page_title": "Piloter par vous-même un portefeuille d'actions éligibles au PEA",
      "profitability-metric": "Métrique de profitabilité",
      "value-metric": "Métrique de value",
      "market-cap-range": "Filtre par capitalisation",
      "apply-filters": "Appliquer ces filtres :",
      "max-500": "Notez que seules 500 actions seront affichées au maximum.",
      "value-threshold": "Seuil Value",
      "profitability-threshold": "Seuil Profitability",
      "investment-threshold": "Seuil Investment",
      "momentum-threshold": "Seuil Momentum",
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;