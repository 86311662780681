import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Typography from '@mui/material/Typography';
import GoogleButton from 'react-google-button'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { URL_BACKEND } from './Constantes';
import { useTranslation } from 'react-i18next';

function PortfolioDeconnected() {
    const { t } = useTranslation();
    return (<div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:'1em' }}>
        <GoogleButton label={t('signin')} type="dark" onClick={() => { window.location.replace(`${URL_BACKEND}/oauth2/authorization/google`); }} />
        </div>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{t('usage')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{t('usageDetails')}</Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{t('googleAccount')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{t('googleAccountDetails1')}</Typography>
                <Typography>{t('googleAccountDetails2')}</Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{t('free')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{t('freeDetails')}</Typography>
            </AccordionDetails>
        </Accordion>
    </div>);
}

export default PortfolioDeconnected;