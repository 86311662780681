import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';

function LanguagePicker() {
    const { i18n } = useTranslation();
    return (
        <ButtonGroup aria-label="language picker">
            <Button startIcon={<Flag code={"GB"} style={{ height: 20}} />} style={{fontWeight: i18n.resolvedLanguage === "en" ? 'bold' : 'normal'}} variant="outlined" type="submit" onClick={() => i18n.changeLanguage("en")}> English</Button>
            <Button startIcon={<Flag code={"FR"} style={{ height: 20}} />} style={{fontWeight: i18n.resolvedLanguage === "fr" ? 'bold' : 'normal'}} variant="outlined" type="submit" onClick={() => i18n.changeLanguage("fr")}> Français</Button>
        </ButtonGroup>
    );
}

export default LanguagePicker;