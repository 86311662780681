import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { URL_BACKEND } from './Constantes';
import { useTranslation } from 'react-i18next';

export default function AddNewStock(props) {
    const { t } = useTranslation();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const [response, setResponse] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const handleChange = (event, value) => {setSelectedOptions(value);setResponse("")}
    const handleSubmit = () => {

        if (selectedOptions) {
            const url = `${URL_BACKEND}/portfolio/add/${selectedOptions}`;

            fetch(url, {
                headers: new Headers({
                    'Authorization': `Bearer ${props.token}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }),
                method: 'POST',
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setResponse(`${selectedOptions} ${t('added')}`);
                        setSelectedOptions([]);
                        setSelectedValue(null);
                        props.handler();
                    },
                    (error) => {
                        console.log(error);
                        if (props.token) {
                            window.location.replace(`${URL_BACKEND}/oauth2/authorization/google`);
                        }
                    }
                )
        } else {
            setResponse(t('select'));
        }
    }

    useEffect(() => {
        const url = `${URL_BACKEND}/stock/buyable`;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return <div>Error : {error.message}</div>;
    } else if (!isLoaded || !items) {
        return <div>Loading...</div>;
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="add-new-stock"
                        options={items}
                        sx={{ width: 300, height: 60 }}
                        renderInput={(params) => <TextField {...params} label={t('buyanother')} />}
                        onChange={handleChange}
                        value={selectedValue}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button variant="outlined" endIcon={<AddCircleIcon />} sx={{ height: 56 }} disabled={selectedOptions.length === 0 ? true : false} onClick={handleSubmit}>
                        {response === '' ? t('addportfolio') : response}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}