import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const RenderValueProfitabilityRank = (props) => {
    const { value } = props;
    if(typeof value=='number'){
      if(value>=70){
        return <>{value} <SentimentSatisfiedAltIcon color='success' /></>
      }
      if(value>=60){
        return <>{value} <SentimentNeutralIcon color='warning' /></>
      }
      return <>{value} <SentimentVeryDissatisfiedIcon color='error' /></>
    }
  };
  
  const RenderInvestmentRank = (props) => {
    const { value } = props;
    if(typeof value=='number'){
      if(value>=20){
        return <>{value} <SentimentSatisfiedAltIcon color='success' /></>
      }
      return <>{value} <SentimentVeryDissatisfiedIcon color='error' /></>
    }
  };
  
  const RenderMomentumRank = (props) => {
    const { value } = props;
    if(typeof value=='number'){
      if(value>=50){
        return <>{value} <SentimentSatisfiedAltIcon color='success' /></>
      }
      return <>{value} <SentimentVeryDissatisfiedIcon color='error' /></>
    }
  };

  export {RenderValueProfitabilityRank, RenderInvestmentRank, RenderMomentumRank};