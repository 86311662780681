import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { URL_BACKEND } from './Constantes';

function BuyComponent(props) {
    const url = `${URL_BACKEND}/portfolio/add/${props.ticker}`;

    return (<button onClick={() =>

        fetch(url, {
            headers: new Headers({
                'Authorization': `Bearer ${props.token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            method: 'POST',
        })
            .then(res => res.json())
            .then(
                (result) => {
                    props.handler();
                },
                (error) => {
                    console.log(error);
                    if (props.token) {
                        window.location.replace(`${URL_BACKEND}/oauth2/authorization/google`);
                    }
                }
            )

    }><AddCircleIcon /></button>);
}

export default BuyComponent;
