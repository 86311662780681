import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TableToBuy from './TableToBuy';
import { HashLink as Link } from 'react-router-hash-link';
import ChartPastPerf from './ChartPastPerf';
import RegressionTable from './RegressionTable';
import LoginIcon from '@mui/icons-material/Login';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import LanguagePicker from './LanguagePicker';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga4';
import {GOOGLE_ANALYTICS} from './Constantes';

function App(props) {
    ReactGA.initialize(GOOGLE_ANALYTICS);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(props.forceLanguage==="fr"){
            i18n.changeLanguage("fr");
        }
    }, [props.forceLanguage, i18n]);

    useEffect(() => {
        document.title = t('title');
        document.documentElement.lang=i18n.language;
        document.getElementsByTagName('meta')["description"].content = t("seo_about_us_page_title");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    }, [t, i18n]);

    let navigate = useNavigate();
    const loginPage = () => {
        let path = `/Portfolio`;
        navigate(path);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CssBaseline />
            <Grid alignContent={'center'} justify="center" width="95%" maxWidth="975px">
                <Box style={{ textAlign: 'right' }}>
                    <LanguagePicker />
                    <ButtonGroup aria-label="login" style={{ "paddingLeft": "2em" }}>
                        <Button startIcon={<LoginIcon />} variant="outlined" onClick={loginPage}> {t('login')}</Button>
                    </ButtonGroup>
                </Box>
                <Typography variant="h3" component="div" gutterBottom>
                    {t('title')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('introduction1')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('introduction2')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('introduction3')} <Link to={"#Methodology"}>{t('methodology')}</Link>.
                </Typography>
                <Typography variant="h4" component="div" gutterBottom>
                    {t('pastPerformance')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('chartIntroduction')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('chartDetails')}
                </Typography>
                <ChartPastPerf />
                <Typography variant="body1" gutterBottom>
                    {t('regressionIntroduction')}
                </Typography>
                <RegressionTable />
                <div style={{ height: '30px' }}></div>
                <Typography variant="h4" component="div" gutterBottom>
                    {t('myPortfolio')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('myPortfolioDescription1')} <Link to={"#Methodology"}>{t('methodology2')}</Link>. {t('myPortfolioDescription2')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('yourPortfolio1')} <Link to={"./Portfolio"}>{t('createAccount')}</Link> {t('yourPortfolio2')}.
                </Typography>
                <TableToBuy type={'admin'} marketCapDisabled={true} advancedFiltersDisabled={true} />
                <div style={{ height: '200px' }}></div>
                <Typography variant="h4" component="div" gutterBottom>
                    {t('buy')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('buyIntroduction1')} <Link to={"#Methodology"}>{t('methodology')}</Link>, {t('buyIntroduction2')}
                </Typography>
                <TableToBuy type={'buy'} marketCapDisabled={false} advancedFiltersDisabled={false} />
                <div style={{ height: '200px' }}></div>
                <Typography variant="h4" component="div" gutterBottom id="Methodology">
                    {t('methodology3')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('methodologyIntroduction')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('methodologySources')}
                </Typography>
                <ul>
                    <li>Bessembinder 2018 <i>Do Stocks Outperform Treasury Bills?</i></li>
                    <li>Fama French 2014 <i>A five-factor asset pricing model</i></li>
                    <li>Novy-Marx 2013 <i>The Other Side of Value: The Gross Profitability Premium</i></li>
                </ul>
                <Typography variant="h5" component="div" gutterBottom>
                    {t('methodologyUniverse')}
                </Typography>
                <ul>
                    <li>{t('universe1')}</li>
                    <li>{t('universe2')}</li>
                    <li>{t('universe3')}</li>
                </ul>
                <Typography variant="h5" component="div" gutterBottom>
                    {t('methodologyRanking')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('ranking1')}
                </Typography>
                <ul>
                    <li>{t('ranking2')}</li>
                    <li>{t('ranking3')}</li>
                    <li>{t('ranking4')}</li>
                    <li>{t('ranking5')}</li>
                </ul>
                <Typography variant="body1" gutterBottom>
                    {t('ranking6')}
                </Typography>
                <Typography variant="h5" component="div" gutterBottom>
                    {t('methodologyBuy')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('buy1')}
                </Typography>
                <ul>
                    <li>{t('buy2')}</li>
                    <li>{t('buy3')}</li>
                    <li>{t('buy4')}</li>
                    <li>{t('buy5')}</li>
                </ul>
                <Typography variant="h5" component="div" gutterBottom>
                    {t('methodologySell')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('sell1')}
                </Typography>
                <ul>
                    <li>{t('sell2')}</li>
                </ul>
                <Typography variant="body1" gutterBottom>
                    {t('sell3')}
                </Typography>
                <ul>
                    <li>{t('sell4')}</li>
                    <li>{t('sell5')}</li>
                    <li>{t('sell6')}</li>
                </ul>
                <Typography variant="h5" component="div" gutterBottom>
                    {t('methodologyWeighting')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('weightingDetails')}
                </Typography>
                <Typography variant="h5" component="div" gutterBottom>
                    {t('methodologyResources')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('resources1')} <a href="https://www.pwlcapital.com/resources/five-factor-investing-with-etfs/">Five Factor Investing with ETFs</a> {t('and')} <a href="https://www.avantisinvestors.com/content/dam/ac/pdfs/ipro/viewpoint/iuo/scientific-approach-to-investing.pdf">Our Scientific Approach To Investing</a>. {t('resources2')}
                </Typography>
                <Typography variant="h4" component="div" gutterBottom>
                    {t('faq')}
                </Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('avws')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('avwsDetails')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('zprx')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('zprxDetails')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('ie')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('ieDetails1')}</Typography>
                        <Typography>{t('ieDetails2')} Bessembinder 2018 <i>Do Stocks Outperform Treasury Bills?</i></Typography>
                        <Typography>{t('ieDetails3')} <Link to={"#Methodology"}>{t('methodology4')}</Link> {t('ieDetails4')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('mms')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('mmsDetails')} Blitz & Hanauer 2021 <i>Settling the size matter</i>.</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('fees')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('feesDetails1')}</Typography>
                        <Typography>{t('feesDetails2')}</Typography>
                        <Typography>{t('feesDetails3')}</Typography>
                        <Typography>{t('feesDetails4')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('rebalance')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('rebalanceDetails')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('accumulatingWrapper')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('accumulatingWrapperDetails')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('profitabilityMetric')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('profitabilityMetricDetails1')} <i>The Other Side of Value: The Gross Profitability Premium</i>. {t('profitabilityMetricDetails2')} <i>A five-factor asset pricing model</i>, {t('profitabilityMetricDetails3')}.
                        </Typography>
                        <Typography>{t('profitabilityMetricDetails4')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('outsideEEA')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('outsideEEADetails')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('avdv')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('avdvDetails1')}</Typography>
                        <Typography>{t('avdvDetails2')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('howmanystocks')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Bessembinder 2018 <i>Do Stocks Outperform Treasury Bills?</i> {t('howmanystocksDetails1')}
                        </Typography>
                        <Typography>{t('howmanystocksDetails2')}</Typography>
                        <Typography>{t('howmanystocksDetails3')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('sectorcap')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('sectorcapDetails')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('broker')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('brokerDetails1')}</Typography>
                        <Typography>{t('brokerDetails2')}</Typography>
                        <Typography>{t('brokerDetails3')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <div style={{ height: '50px' }}></div>
            </Grid>
        </div>
    );
}

export default App;