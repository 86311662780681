import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

function createData(name, mkt, smb, hml, rmw, cma, wml, alpha, r) {
  return { name, mkt, smb, hml, rmw, cma, wml, alpha, r };
}

const rows = [
  createData('factorinvesting.eu', '1.00 (0.00)', '0.82 (0.00)', '0.35 (0.01)', '0.29 (0.15)', '0.12 (0.57)', '0.19 (0.03)', '0.53 (0.11)', '95%'),
  createData('Indépendance et Expansion Europe Small A(C)', '1.13 (0.00)', '0.80 (0.00)', '0.46 (0.02)', '0.59 (0.05)', '0.10 (0.76)', '0.20 (0.11)', '0.52 (0.31)', '91%'),
  createData('SPDR MSCI Europe Small Cap Value Weighted', '1.18 (0.00)', '0.82 (0.00)', '0.40 (0.00)', '0.21 (0.23)', '0.20 (0.27)', '-0.06 (0.38)', '-0.15 (0.62)', '98%'),
  createData('iShares MSCI EMU Small Cap', '1.21 (0.00)', '0.60 (0.00)', '0.41 (0.00)', '0.18 (0.27)', '0.03 (0.86)', '0.10 (0.15)', '-0.68 (0.02)', '98%'),
  createData('MSCI EMU Micro Cap', '1.03 (0.00)', '0.89 (0.00)', '0.12 (0.42)', '-0.16 (0.49)', '0.06 (0.82)', '0.06 (0.55)', '-0.12 (0.75)', '94%'),
];

export default function RegressionTable() {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('name')}</TableCell>
            <TableCell align="right">Mkt-RF</TableCell>
            <TableCell align="right">SmB</TableCell>
            <TableCell align="right">HmL</TableCell>
            <TableCell align="right">RmW</TableCell>
            <TableCell align="right">CmA</TableCell>
            <TableCell align="right">WmL</TableCell>
            <TableCell align="right">α</TableCell>
            <TableCell align="right">R<sup>2</sup></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.mkt}</TableCell>
              <TableCell align="right">{row.smb}</TableCell>
              <TableCell align="right">{row.hml}</TableCell>
              <TableCell align="right">{row.rmw}</TableCell>
              <TableCell align="right">{row.cma}</TableCell>
              <TableCell align="right">{row.wml}</TableCell>
              <TableCell align="right">{row.alpha}</TableCell>
              <TableCell align="right">{row.r}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
