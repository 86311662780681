import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useSearchParams } from "react-router-dom";
import PortfolioConnected from "./PortfolioConnected"
import PortfolioDeconnected from "./PortfolioDeconnected"
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';
import LanguagePicker from './LanguagePicker';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga4';
import { GOOGLE_ANALYTICS } from './Constantes';

function Portfolio(props) {
    ReactGA.initialize(GOOGLE_ANALYTICS);

    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(props.forceLanguage==="fr"){
            i18n.changeLanguage("fr");
        }
    }, [props.forceLanguage, i18n]);

    useEffect(() => {
        document.title = `${t('yourportfolio')} | ${t('title')}`;
        document.documentElement.lang=i18n.language;
        document.getElementsByTagName('meta')["description"].content = t("seo_about_us_page_title");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    }, [t, i18n]);

    let navigate = useNavigate();
    const homePage = () => {
        let path = `/`;
        navigate(path);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CssBaseline />
            <Grid alignContent={'center'} justify="center" width="95%" maxWidth="1000px" >
                <Box style={{ textAlign: 'right' }}>
                    <LanguagePicker />
                    <ButtonGroup aria-label="home" style={{"paddingLeft":"2em"}}>
                        <Button startIcon={<HomeIcon />} variant="outlined" onClick={homePage}> {t('back')}</Button>
                    </ButtonGroup>
                </Box>
                <Typography variant="h3" component="div" gutterBottom>{t('yourportfolio')}</Typography>
                {searchParams.get("token") != null ? <PortfolioConnected /> : <PortfolioDeconnected />}
            </Grid>
        </div>
    );
}

export default Portfolio;